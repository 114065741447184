import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store';
import { whenExtensionMounted } from '@/utils';

const routes = [
  {
    path: '/',
    children: [
      {
        path: '',
        component: () => import('../layouts/LoginLayout.vue'),
        children: [
          {
            path: 'signIn',
            name: 'signIn',
            component: () => import('../views/AuthView.vue'),
            meta: {
              title: 'Sign in'
            }
          },
          {
            path: 'signUp',
            name: 'signUp',
            component: () => import('../views/AuthView.vue'),
            meta: {
              title: 'Sign up'
            }
          }
        ]
      },
      // TODO: remove this once extension is updated on Chrome Web Store
      {
        path: '/auth',
        component: () => import('../layouts/LoginLayout.vue'),
        redirect: { name: "signIn" },
        children: [
          {
            path: 'signIn',
            name: 'signIn-deprecated',
            component: () => import('../views/AuthView.vue'),
            meta: {
              title: 'Sign in'
            }
          },
          {
            path: 'signUp',
            name: 'signUp-deprecated',
            component: () => import('../views/AuthView.vue'),
            meta: {
              title: 'Sign up'
            }
          }
        ]
      },
      {
        path: '/home',
        name: 'home',
        meta: { auth: true },
        component: () => import('../views/HomeView.vue'),
      },
      {
        path: '/verify-account-registration/:id/:email/:referralCode?',
        name: 'verifyRegistration',
        component: () => import('../views/VerifyRegistrationView.vue')
      },
    ]
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async ({ meta, query, path }, from, next) => {
  const userEmail = store.state.auth.user?.name
  const title = (meta.title ? (meta.title + ' | ') : "") + 'PitchMeAI - Get More Job Offers'
  document.title = title

  if (meta?.auth || path === "/") {
    // google login / direct route enter / from other app route and not logged in
    if(!userEmail) {
      await store.dispatch('get_user', { next, redirectHandler: () => handleRedirect(query) })
    }

    // from other app route and logged in (regular login or else)
    if(userEmail) {
      handleRedirect(query).catch(console.error)
    }

    return
  }

  next()
});

export default router

async function handleRedirect(query) {
  const referrer = JSON.parse(localStorage.getItem('PitchMeAI:referrer'))
  const referrerUrl = referrer?.url;
  const signUpParam = !!query?.['new-user-email'] ? "&pitchmeai-new-user=true" : ""
  const extensionInstalled = !!query.isExtensionInstalled || await whenExtensionMounted()

  if (!extensionInstalled) {
    window.location.href = process.env.VUE_APP_CHROMESTORE_URL
    return 
  }
  
  if (referrerUrl) {
    localStorage.removeItem('PitchMeAI:referrer');
    window.location.href = decodeURIComponent(referrerUrl) + signUpParam
    return
  }
  
  window.location.href = 'https://www.linkedin.com/jobs/collections/recommended/?pitchmeai-show-drawer=true' + signUpParam
}
