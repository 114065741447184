import axios from "axios"
import api from "@/store/api"
import router from '@/router'
import {whenExtensionMounted} from "@/utils"
import {AnalyticsEvents} from "@/plugins/analytics/events";

export const state = {
  user: {},
  responseMsg: '',
  wrongCredentials: false,
  accountNotActive: false,
  emailAlreadyUsed: false,
  utmSource: null
}

export const mutations = {
  SET_AUTH_STATE (state, {key, value}) {
    state[key] = value
  },
  SET_USER (state, data) {
    state.user = data
  },
  SET_UTM_SOURCE (state, value) {
    state.utmSource = value
  },
  SET_RESP_MSG (state, msg) {
    state.responseMsg = msg
  },
  RESET_AUTH_MSGS (state) {
    state.responseMsg = ''
    state.wrongCredentials = false
    state.accountNotActive = false
    state.emailAlreadyUsed = false
  }
}

export const actions = {
  async signIn ({ state, commit }, {payload, redirectParams}) {
    try {
      commit('RESET_AUTH_MSGS');

      const { data } = await axios.post(api.login, payload);
      this.$analytics.trackEvent(AnalyticsEvents.SIGN_IN, {
        provider: 'credentials',
        email: payload.email,
      })
      commit('SET_USER', data);
      await router.push({name: 'home', query: redirectParams});
    } catch (err) {
      err.response?.status === 401 && commit('SET_AUTH_STATE', { key: 'wrongCredentials', value: true });
      err.response?.status === 402 && commit('SET_AUTH_STATE', { key: 'accountNotActive', value: true });
    }
  },
  async get_user ({ commit }, {next, redirectHandler}) {
    try {
      commit('SET_AUTH_STATE', { key: 'accountNotActive', value: false });
      const { data: user } = await axios.get(api.login);

      user.name && commit('SET_USER', user);
      redirectHandler()
    } catch (err) {
      err.response?.status === 402 && commit('SET_AUTH_STATE', { key: 'accountNotActive', value: true });
      next('/auth/signIn');
    }
  },
  async signUp ({state, commit}, {payload}) {
    try {
      commit('RESET_AUTH_MSGS');
      await axios.post(
          api.signup,
          payload,
          {
            params: {
              isExtensionInstalled: await whenExtensionMounted(),
              utmSource: state.utmSource
            }
          }
      )
      this.$analytics.trackEvent(AnalyticsEvents.SIGN_UP, {
        provider: 'credentials',
        email: payload.email,
      })
      commit('SET_AUTH_STATE', { key: 'responseMsg', value: "Before getting started, you need to validate your account. Please check your email and click the link. If you don't see our email in your inbox, please check your spam folder." });
    } catch (err) {
      err.response?.status === 409 && commit('SET_AUTH_STATE', { key: 'emailAlreadyUsed', value: true });
      err.response?.status === 405 && commit('SET_AUTH_STATE', { key: 'accountNotActive', value: true });
      // commit('SET_RESP_MSG', err.response?.data?.msg || 'Email already in use');
    }
  },
  async verify_signup ({commit}, {id, email, referralCode}) {
    try {
      await axios.post(`${api.verifySignup}/${id}/${email}${referralCode ? '/' + referralCode : ''}`)
      this.$analytics.trackEvent(AnalyticsEvents.EMAIL_VERIFIED, {
        email,
        referralCode,
      })
      commit('SET_RESP_MSG', 'Account verified, you will be redirected to login page in few moments.');

      const referrer = JSON.parse(localStorage.getItem('PitchMeAI:referrer'))
      const referrerUrl = referrer?.url;

      setTimeout(() => {
        commit('SET_RESP_MSG', '');
        commit('RESET_AUTH_MSGS');

        const query = {
          'new-user-email': email,
        };
        
        if (referrerUrl) {
          query.referrer = referrerUrl;
        }
        
        router.push({
          name: "signIn",
          query: query,
        });
      }, 3000)
    } catch (err) {
      commit('SET_RESP_MSG', 'Your account could not be verified. Please send us an email at support@pitchmeai.com or try to signup again.');
      console.log(err)
    }
  },
  async forgot_pass ({commit}, {email}) {
    try {
      await axios.post(api.resetPass, {email})
    } catch (err) {
      console.log(err)
    }
  }
}
