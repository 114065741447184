export function buildUrl(base, params) {
    const queryString = Object.keys(params)
      .filter(key => params[key] !== undefined && params[key] !== null)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
      .join('&')
        
    return queryString ? `${base}?${queryString}` : base;
}

export function whenExtensionMounted() {
    if (whenExtensionMounted._isMounted !== undefined)
        return whenExtensionMounted._isMounted
    whenExtensionMounted._isMounted = new Promise(resolve => {
        let checkingExtensionMount = undefined;

        // This will resolve when th extension is installed
        const handler = ev => {
            if (ev.data.type !== 'wxt:content-script-started') return

            // This will prevent windowLoaded listener from resolving prematurely with false.
            checkingExtensionMount = true
            window.removeEventListener('message', handler)

            // Todo: we can eliminate this guess by sending event from the app when mounted
            setTimeout(() => {
                resolve(hasExtensionRoot())
            }, 1000) // Guessing that the app is mounted withing 1 sec
        }
        window.addEventListener('message', handler)

        // This will resolve when the extension is not installed,
        // and it's been 2 seconds since the page loaded successfully.
        // Provided we're not already checking for app-mounted event above.
        window.addEventListener('load', () => {
            setTimeout(() => {
                if (checkingExtensionMount !== undefined) return
                window.removeEventListener('message', handler)
                resolve(hasExtensionRoot())
            }, 2000) // Guessing that the iframe must have been loaded withing 2 sec
        })
    })
    return whenExtensionMounted._isMounted
}

function hasExtensionRoot() {
  return !!document.querySelector('[data-wxt-iframe]')
}

export class Utils {
    static removeEmptyProperties(obj) {
        return Object.entries(obj).reduce(
            (acc, [key, value]) => {
                if (
                    value !== null &&
                    value !== undefined &&
                    value !== "" &&
                    value !== 0 &&
                    !Number.isNaN(value) &&
                    (Array.isArray(value) ? value.length > 0 : true) &&
                    (typeof value === "object" && value !== null
                        ? Object.keys(value).length > 0
                        : true)
                ) {
                    acc[key] =
                        typeof value === "object" && !Array.isArray(value)
                            ? Utils.removeEmptyProperties(value)
                : value
                }
                return acc
            },
            {}
        )
    }
}
