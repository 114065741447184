<template>
  <div class="main-content h100">
    <router-view/>
  </div>
</template>

<style lang="scss">
  @import "./styles/vars";

  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
</style>

<script setup>
import {onMounted} from "vue";
import {useStore} from "vuex";
import {useAnalytics} from "@/composables/useAnalytics";
import {whenExtensionMounted} from "@/utils";

const { analytics } = useAnalytics()
const { commit } = useStore()

onMounted(() => {
  const urlParams = new URLSearchParams(window.location.search);
  const utmSource = urlParams.get('utm_source') ?? urlParams.get('pitchmeai-utm-source')
  commit("SET_UTM_SOURCE", utmSource)

  whenExtensionMounted().then(hasExtension => {
    if (hasExtension)
      analytics.setGlobalProperties({
        hasExtensionInstalled: hasExtension
      })
    analytics.pageTracking.start()
  })
})
</script>
